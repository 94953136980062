export const photos1 = [
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585214592/glp/whiteled/1bodylightfront_ul6z5v.jpg",
      width: 3,
      height: 3,
      className: 'gallery',
    },
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585214593/glp/whiteled/5indoorESLsolarLED_chbezu.jpg",
      width: 3,
      height: 3
    },
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585214593/glp/whiteled/6indoor_a9h2hu.jpg",
      width: 3,
      height: 3
    },
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585214593/glp/whiteled/7.5solar_ntmu2h.jpg",
      width: 3,
      height: 3
    },
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585214595/glp/whiteled/7indoor_rwtulw.jpg",
      width: 3,
      height: 3
    },
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585214595/glp/whiteled/8IMG1860_woznjw.jpg",
      width: 3,
      height: 3
    },
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585214592/glp/whiteled/10IMG1862_gbjzus.jpg",
      width: 3,
      height: 3
    },
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585214592/glp/whiteled/11IMG1867_yephz8.jpg",
      width: 3,
      height: 3
    },
  ];
  