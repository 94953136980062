import React from 'react'

import '../../css/HomePageCss/homeheadertitle.css'

const Homeheadertitle = () => (
    <section className="hheader">
    <div className="row headline">
        <div className="container-fluid">
            
        	<div className="col-lg-12">
            	<h2>
                We believe in a <span className="greenText">sustainable</span> world, where life can <span className="orangeTextTitle">thrive</span>
                </h2>
                
                <div className="container">
                    <h3>
                        Over 1.6 Billion people still live without reliable access to electricity, clean water, sanitary cooking facilities, or the resources necessary to grow food for themselves or their families.
                        Using the power of renewable energy and revolutionary LED light solutions, <br /> <span className="orangeTextSubtitle">the Global Lighting Project is on a mission to change that.</span>
                    </h3>
                </div>
            </div>
            
        </div>
    </div>
    
</section>
)

export default Homeheadertitle
