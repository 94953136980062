export const photos2 = [
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585260275/glp/growlight/upclosegrowlightstrip_b1kud7.jpg",
      width: 1,
      height: 1,
      className: 'gallery',
    },
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585260274/glp/growlight/spectrum-growlights_ctda1y.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585260274/glp/growlight/LED_grow_strip_ligh1_ryfxjc.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://res.cloudinary.com/dexdumfqy/image/upload/v1585260274/glp/growlight/growlightpurple2plants_ha2nx6.jpg",
      width: 1,
      height: 1
    },
  ];
  